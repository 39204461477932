import React, { useState, useEffect, useRef, useCallback } from "react";
import plusIcon from "../../assets/plusIcon.svg";
// import closeIcon from "../../assets/close.svg";
// import crossIcon from "../../assets/cross.svg";

import clockIcon from "../../assets/clock.png";
import submitLogo from "../../assets/icon-submit.svg";
import cancelLogo from "../../assets/icon-cancel.svg";
import clockIcon2 from "../../assets/clockIcon.svg";
import loadingGif from "../../assets/loading-white.gif";
import crossIcon from "../../assets/cross.svg";
import cameraCapture from "../../assets/cameraCapture.svg";
import cameraSwitch from "../../assets/cameraRotate.svg";
// import submitLogo from "../../assets/icon-submit.svg";
import resetLogo from "../../assets/reset.svg";
import addMore from "../../assets/plus-circle.png";

import Webcam from "react-webcam";
import { v4 as uuidv4 } from "uuid";
import axios from "axios";
import config from "../Utils/config";
import { useNavigate, Link } from "react-router-dom";
import PunchPopup from "../popupModal/PunchPopup";
import Popup from "../popupModal/Popup";
import ErrorPopup from "../popupModal/ErrorPopup";
import Select from 'react-select';
import Datepicker from "react-datepicker";
import moment from "moment";
// import Webcam from "react-webcam";
import "./attendance.css"
// import "./video.css";

const dealer = ["ABCCJ", "SDNSN", "SDHXK", "SDSKMX", "CHJSXJSJJ"];

const Api_key = "1fa36f9acee66d4204a98fe01d0b1fce";
const Api_endpoint = `https://api.openweathermap.org/data/3.0/onecall?`;
const selectCustomStyles = {
  menu: (provided, state) => ({
    ...provided,
    backgroundColor: "white",
    borderBottom: "0px solid #ccc",
    borderRadius: "0",
    marginTop: "8px",
  }),
  menuList: (provided, state) => ({
    maxHeight: "150px",
    overflow: "auto",
    "&::-webkit-scrollbar": {
      width: "4px",
    },
    "&::-webkit-scrollbar-track": {
      background: "#f1f1f1",
      borderRadius: "4px",
    },
    "&::-webkit-scrollbar-thumb": {
      background: "#D9D9D9",
      borderRadius: "10px",
      transition: "all linear 0.5s",
    },
    "&::-webkit-scrollbar-thumb:hover": {
      background: "#bababa",
    },
  }),
  option: (provided, state) => ({
    ...provided,
    height: "30px",
    width:'100%',
    lineHeight: "1",
    backgroundColor: state.isFocused ? "rgba(0,0,0,0.1)" : "white",
    color: state.isFocused ? "#000" : "black",
    fontSize: "14px",
    "&:hover": {
      backgroundColor: state.isFocused ? "rgba(0,0,0,0.1)" : "#f2f2f2",
      color: state.isFocused ? "#000" : "black",
    },
  }),
  control: (base, state) => ({
    ...base,
    borderRadius: "none",
    border: "1px solid rgb(209 213 219)",
    // borderBottom: "1px solid #ccc",
    boxShadow: "none",
    "&:hover": {
      border: "none",
      borderBottom: "1px solid #ccc",
    },
    width: "100%",
    fontSize: "14px",
    color: "#000",
    borderRadius:"4px",
    height:'36px'
  }),
};

function Attendance() {
  const navigate = useNavigate();
  let phoneNum = localStorage.getItem("superonUserPhNum");

  const [date, setDate] = useState("15/08/1947");
  const [timePunchIn, setTimePunchIn] = useState("00:00");
  const [timePunchOut, setTimePunchOut] = useState("00:00");

  // camera modal
  const [isCameraModalOpen, setIsCameraModalOpen] = useState(false);
  const [isCameraModalOpenMobile, setIsCameraModalOpenMobile] = useState(false);
  const [isWebCamOpen, setIsWebCamOpen] = useState(true);
  const [showImage, setShowImage] = useState(false);
  const [showCapture, setShowCaptured] = useState(true);
  const [image, setImage] = useState("");

  const [photo, setPhoto] = useState("");
  const [odometerPhoto, setOdometerPhoto] = useState("");
  const fileInputRef = useRef(null);
  const [odometerDetails, setOdometerDetails] = useState({
    meterReading: "",
    remark: "",
    order_collected: null,
    new_ss_dealer_visited: null,
    new_ss_fabricator_demo: null,
    new_ss_retailer_visited: null
  });

  const [picture, setPicture] = useState("");
  const webcamRef = React.useRef(null);
  const [facingMode, setFacingMode] = useState("environment");

  // checkin and Out
  const [showCheckIn, setShowCheckIn] = useState(false);
  const [showCheckOut, setShowCheckOut] = useState(false);
  const [dealerName, setDealerName] = useState("");
  const [productName, setProductName] = useState("");
  const [dealerLocation, setDealerLocation] = useState("");
  const [dealerMobile, setDealerMobile] = useState("");
  const [targetPrice, setTargetPrice] = useState("");
  const [productQty, setProductQty] = useState("");
  const [customDealerName, setCustomDealerName] = useState("");
  const [electrodeMigTigRemark, setElectrodeMigTigRemark] = useState("");
  const [mcaMspRemark, setMcaMspRemark] = useState("");
  // const [wAuxRemark, setWAuxRemark] = useState("");
  const [remark, setRemark] = useState("");
  const [gift, setGift] = useState("");
  const [dealerPunchInTime, setDealerPunchInTime] = useState("");
  const [dealerPunchOutTime, setDealerPunchOutTime] = useState("");
  const [dealerCheckInandOutList, setDealerCheckInandOutList] = useState([]);
  const intervalRef = useRef(null);
  const [dealerPunchInName, setDealerPunchInName] = useState("");
  const [punchInType, setPunchInType] = useState("");
  const [dealerExtras,setDealerExtras]=useState({
    dealermobile:"",
    dealerlocation:""
  })
  const [uuid, setDealerUuid] = useState("");
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [punchMessage, setPunchMessage] = useState("");
  const [showLoading, setShowLoading] = useState(false);
  const [isModalOpen2, setIsModalOpen2] = useState(false);
  const [showErrorModal, setShowErrorModal] = useState(false);
  const [message, setMessage] = useState("");
  const [selectedType, setSelectedType] = useState([]);
  const [typeOptions, setTypeOptions] = useState([]);
  const [selectedAction, setSelectedAction] = useState([]);
  const [actionOptions, setActionOptions] = useState([]);
  const [visitDate, setVisitDate] = useState('');
  // Dealer list
  const [dealerList, setDealerList] = useState([]);
  const [showDealerList, setShowDealerList] = useState(false);
  const [dealerListCopy, setDealerListCopy] = useState([]);

  //  punch in and out state
  const [isPunchIn, setIsPunchIN] = useState(false);
  const [isPunchOut, setIsPunchOut] = useState(false);
  const [showAddButton, setShowAddButton] = useState(false);

  const [weeklyPlanStatus,setWeeklyPlanStatus]=useState(99);

  const [errorObj,setErrorObj] = useState({});
  const [sampleDealerName,setSampleDealerName] = useState("");
  const [sampleRequest,setSampleRequest] = useState([
    {
      id: uuidv4(),
      item: '',
      dimension: '',
      totalquantity: '',
      quantityperpacket: ''
    }
  ]);
  const [dealerInfo,setDealerInfo] = useState({
    existing: '',
    name: sampleDealerName,
    address: '',
    state: '',
    city: '',
    language: ''
  });
  const [errors,setErrors] = useState({});
  const [addsamplerequest, setAddsamplerequest] = useState(null);
  const [addsamplerequestStatus,setAddsamplerequestStatus] = useState(false)
  
  // Location

  // Location
  const getCurrenttLocation = async () => {
    return new Promise((resolve, reject) => {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          const latitude = position.coords.latitude;
          const longitude = position.coords.longitude;
          console.log(latitude, longitude);
          resolve({ latitude, longitude });
        },
        (error) => {
          reject(error);
        }
      );
    });
  };

  // blockUser
  useEffect(() => {
    let phoneNum = localStorage.getItem("superonUserPhNum");

    if (!phoneNum) {
      navigate("/phone");
    }
  }, []);

  // useEffect(() => {
  //   getCurrenttLocation();
  // });

  // useEffect(() => {
  //   let interval = setInterval(() => {
  //     getCurrenttLocation();
  //   }, 10000);

  //   return () => {
  //     clearInterval(interval);
  //   };
  // }, []);

  const handleDealerName = (dealerName) => {
    console.log(dealerName);
    // console.log(dealerRef.current.innerText);
    setDealerName(dealerName);

    setShowDealerList(false);
  };

  const handleDealerChange = (e) => {
    setDealerName(e.target.value);

    let filteredList = dealerListCopy.filter((item, index) => {
      console.log(e.target.value.toLowerCase(), item.dealername.toLowerCase());
      return item.dealername
        .toLowerCase()
        .trim()
        .includes(e.target.value.toLowerCase());
    });

    console.log(filteredList);
    setDealerList(filteredList);
  };

  const getDealers = async () => {
    // setShowLoading(true);
    let phoneNum = localStorage.getItem("superonUserPhNum");

    try {
      let res = await axios.get(config.ipAddress + `/dealers/${phoneNum}`);
      console.log(res.data.data);
      let allData = res?.data?.data;
      allData.sort((a, b) => {
        if (a.dealername.toLowerCase() < b.dealername.toLowerCase()) {
          return -1;
        } else if (a.dealername.toLowerCase() > b.dealername.toLowerCase()) {
          return 1;
        } else {
          return 0;
        }
      });
      setDealerList(allData);
      setDealerListCopy(allData);
      // setShowLoading(false);
    } catch (error) {
      // setShowLoading(false);
    }
  };

  const getDate = () => {
    const currentDate = new Date();
    const year = currentDate.getFullYear(); // 2023
    const month = currentDate.getMonth() + 1; // January is 0, so add 1
    const day = currentDate.getDate(); // 4

    const dateString = `${day < 10 ? "0" : ""}${day}/${
      month < 10 ? "0" : ""
    }${month}/${year}`;
    setDate(dateString);
    console.log(dateString); // "2023-04-04"
  };

  const getTime = () => {
    const currentDate = new Date();
    const hours = currentDate.getHours();
    const minutes = currentDate.getMinutes();
    const amOrPm = hours >= 12 ? "PM" : "AM";
    const twelveHourFormat = hours % 12 || 12;

    const timeString = `${twelveHourFormat}:${
      minutes < 10 ? "0" : ""
    }${minutes} ${amOrPm}`;

    return timeString;
  };

  const getTimePunchIn = () => {
    const timeString = getTime();
    setTimePunchIn(timeString);
    console.log(timeString); // "11:13 PM"
    return timeString;
  };

  const getTimePunchOut = () => {
    const timeString = getTime();
    setTimePunchOut(timeString);
    console.log(timeString); // "11:13 PM"

    return timeString;
  };

  useEffect(() => {
    getDealerCheckInandOutList();
    getDate();

    const currentDate = new Date().getDate();
    localStorage.setItem("currentDate", currentDate);
  }, []);

  // Daily activities functionality
  const [forms, setForms] = useState([]);
  const [formData, setFormData] = useState([]);

  function handleAddClick() {
    // setForms([...forms, { showRemarks: false }]);

    if (!isPunchIn) return alert("Please Punch In first.");
    if (isPunchOut) return alert("You have already Punched Out!");

    if (showCheckOut) return;

    setShowCheckIn(true);
  }

  // dealer checkIn 🚀🚀🚀🚀
  const handleCheckInClick = async () => {
    
    if (!(dealerName || customDealerName)) {
      setMessage("Please Enter Dealer/Customer Name!");
      setShowErrorModal(true);
      return;
    }
    
    
    if (!(dealerMobile)) {
      setMessage("Please Enter Mobile of Dealer !");
      setShowErrorModal(true);
      return;
    }
    
    if (!(dealerLocation)) {
      setMessage("Please Enter Location of Dealer !");
      setShowErrorModal(true);
      return;
    }
    
    if(!selectedType.length){
      setMessage("Please select type.");
      setShowErrorModal(true);
      return;
    }
    
    setShowLoading(true);
    const { latitude, longitude } = await getCurrenttLocation();
    // if (dealerName === "1") {
      //   if (!customDealerName) return alert("Enter Custom Dealer Name");
      // }
      
      if (!(latitude && longitude)) {
        setMessage("Please enable your location!");
        setShowErrorModal(true);
        setShowLoading(false);
        return;
      }
      
      setShowCheckIn(false);
    
    //     setShowCheckOut(true);
    //     setDealerPunchInTime(getTime());
    // setDealerPunchInName()

    // localStorage.setItem("dealerPunchInTime", getTime());
    // localStorage.setItem("showChechOut", true);
    // localStorage.setItem("superonDealerName", customDealerName || dealerName);
    // send data to server

    let phoneNum = localStorage.getItem("superonUserPhNum");
    let dealerId = localStorage.getItem("superonDealerId");

    let selectedDealer = dealerList.filter((list) => {
      return dealerName === list.dealername;
    });

    console.log(selectedDealer);
    if (!dealerName) {
      dealerId = "0";
    }
    let data = {
      mobile: phoneNum,
      dealername: customDealerName || dealerName,
      uuid: uuidv4(), //'9b1deb4d-3b7d-4bad-9bdd-2b0d7b3dcb6d'
      address: "address",
      latitude: latitude + "",
      longitude: longitude + "",
      dealermobile:dealerMobile,
      dealerlocation:dealerLocation,
      dealerid: selectedDealer[0]?.dealerid || dealerId,
      spid: selectedDealer[0]?.spid || "0",
      types: selectedType[0].label
    };
    console.log(data);
    // localStorage.setItem("uuid", data.uuid);

    console.log(data);

    try {
      let response = await axios.post(
        config.ipAddress + `/dealer/punchin`,
        data
      );
      console.log('console log',response);
      localStorage.setItem("dealer_name", data?.dealername)
      // alert(response.data.message);
      setShowLoading(false);
      setSelectedType([])
    } catch (error) {
      setMessage("Network Error!");
      setShowErrorModal(true);
      setShowLoading(false);
    }

    getUserAttendanceState();
  };

  useEffect(() => {
    let showCheckedOut = localStorage.getItem("showChechOut");

    if (!showCheckedOut) return;

    setShowCheckOut(showCheckedOut);
    
  }, [showCheckIn]);

  useEffect(() => {
    setSampleDealerName(localStorage.getItem('dealer_name'))
  },[showCheckOut])

  // dealer checkout 🚀🚀🚀🚀🚀
  const handleCheckOutClick = async () => {
    console.log("ssss", dealerName || localStorage.getItem("dealer_name"))
    setDealerPunchOutTime(getTime());
    // console.log(dealerName, customDealerName, remark, dealerPunchInTime);
    
    // send data to server
    let phoneNum = localStorage.getItem("superonUserPhNum");
    // let uuid = localStorage.getItem("uuid");
    if (!(phoneNum && electrodeMigTigRemark)) {
      setMessage("Please add SS Remarks!");
      setShowErrorModal(true);
      return;
    }
    if (!(phoneNum && mcaMspRemark)) {
      setMessage("Please add MCA/MSP Remarks!");
      setShowErrorModal(true);
      return;
    }
    if (!(phoneNum && remark)) {
      setMessage("Please add W Aux Remarks!");
      setShowErrorModal(true);
      return;
    }
    // if (!selectedAction.length) {
      //   setMessage("Please select action!");
      //   setShowErrorModal(true);
      //   return;
      // }
    if(addsamplerequest === "Yes") {
      if(!validateForm()) {
        return
      }
    }
    else if(addsamplerequest === null) {
      setAddsamplerequestStatus(true)
      return
    }
    else {
      
    }
    setShowLoading(true);
    const { latitude, longitude } = await getCurrenttLocation();

    if (!(latitude && longitude)) {
      setMessage("Please enable your location!");
      setShowErrorModal(true);
      setShowLoading(false);
      return;
    }

    localStorage.removeItem("showChechOut");
    localStorage.removeItem("superonDealerName");

    let data = {
      mobile: phoneNum,
      uuid: uuid,
      remark: remark,
      electrodeMigTigRemark: electrodeMigTigRemark,
      mcaMspRemark: mcaMspRemark,
      latitude: latitude + "",
      longitude: longitude + "",
      gift:gift
      // nextactions:selectedAction[0].label,
      // repeatvisitdate:visitDate ? moment(visitDate).format("DD/MM/YYYY") : "",
      // productname:productName,
      // targetprice:targetPrice,
      // quantity:productQty
    };

    console.log('new remark',data);
    console.log('add sample',addsamplerequest)
    
    // return

    
    const sampleRequestPayload = sampleRequest?.map((item,index)=> {
      return {...item,mobile:localStorage.getItem('superonUserPhNum')}
    })
    const payload = {
      ...dealerInfo,
      name: sampleDealerName,
      mobile:localStorage.getItem('superonUserPhNum'),
      sample_details: sampleRequestPayload
    }
    console.log('payload sample', payload)
    // return
    setShowCheckOut(false);
    setDealerName("");
    setCustomDealerName("");
    setRemark("");
    setElectrodeMigTigRemark("");
    setMcaMspRemark("");
    setGift("")
    setDealerMobile("");
    setDealerLocation("")

    
    try {
      let res = await axios.post(config.ipAddress + `/dealer/punchout`, data);
      console.log(res.data.message);
      if(addsamplerequest === "Yes") {
        let response = await axios.post(config.ipAddress + `/addsamplerequest`, payload)
          console.log('response',response?.data?.message);
        let responseData = response?.data?.message;
        setDealerInfo({
          existing: '',
          name: '',
          address: '',
          state: '',
          city: '',
          language: ''
        })
        setSampleRequest([
          {
            id: uuidv4(),
            item: '',
            dimension: '',
            totalquantity: '',
            quantityperpacket: ''
          }
        ])
        setAddsamplerequest(null)
      }
      
      // alert(res.data.message);
      setShowLoading(false);
      setIsModalOpen2(true);
      setSelectedAction([])
    } catch (error) {
      setMessage("Network Error!");
      setShowErrorModal(true);
      setShowLoading(false);
    }

    getDealerCheckInandOutList();
    getDealers();
  };

  // cancel checkIn
  const handleCancelClick = async () => {
    // let uuid = localStorage.getItem("uuid");
    setShowCheckIn(false);
    setShowCheckOut(false);
    localStorage.removeItem("showChechOut");
    localStorage.removeItem("superonDealerName");
    setDealerName("");
    setCustomDealerName("");
    setDealerLocation("");
    setDealerMobile("");

    try {
      let response = await axios.get(
        config.ipAddress + `/deletecancelvisit/${uuid}`
      );

      console.log(response);
    } catch (error) {
      setMessage("Network Error!");
      setShowErrorModal(true);
      setShowLoading(false);
    }

    getDealerCheckInandOutList();
  };

  // camera modal
  const handleCameraModal = () => {
    setIsCameraModalOpen(false);
  };

  // const handlePunchIn = useCallback(() => {
  //   // setIsCameraModalOpen(true);
  // }, [image]);

  //   punch in and out functionality 🚀🚀🚀🚀🚀🚀🚀🚀🚀🚀🚀🚀🚀🚀🚀🚀🚀

  const handlePunchInPopup = () => {
    setPunchMessage("Are you sure ?");
    setIsModalOpen(true);
  };

  const handlePunchCancelandSuccess = (status) => {
    if (status) {
      if (isPunchIn) {
        handlePunchOut();
        setIsModalOpen(false);
        return;
      }
      handlePunchIn();
      setIsModalOpen(false);
    } else {
      setIsModalOpen(false);
    }
  };

  // const handlePhotoChange = (event) => {
  //   if (!fileInputRef.current.files[0]) return;
  //   const file2 = fileInputRef.current.files[0];

  //   const file = event.target.files[0];
  //   const image = new Image();

  //   image.onload = function () {
  //     const canvas = document.createElement("canvas");
  //     const context = canvas.getContext("2d");
  //     canvas.width = image.width;
  //     canvas.height = image.height;
  //     context.drawImage(image, 0, 0);

  //     // Reduce image quality by compressing it
  //     const compressedDataURL = canvas.toDataURL("image/jpeg", 0.5);

  //     const originalSize = (file.size / 1024).toFixed(2); // in kilobytes
  //     const compressedSize = (compressedDataURL.length / 1024).toFixed(2); // in kilobytes


  //     console.log("Compressed Size:", compressedSize + " KB","Original Size:", originalSize + " KB");

  //     // Set the selected image
  //     setPhoto(compressedDataURL);
  //   };

  //   image.src = URL.createObjectURL(file);
  //   setOdometerPhoto(file2);
  //   // const photoUrl = URL.createObjectURL(file);
  //   // setPhoto(photoUrl);
  // };

  const handlePhotoChange = (event) => {
    if (!fileInputRef.current.files[0]) return;
    const file2 = fileInputRef.current.files[0];

    const file = event.target.files[0];
    const image = new Image();

    image.onload = function () {
        const MAX_SIZE_KB = 200;
        let quality = 0.9; // Start with a high quality
        const canvas = document.createElement("canvas");
        const context = canvas.getContext("2d");

        // Set canvas dimensions to the image dimensions initially
        canvas.width = image.width;
        canvas.height = image.height;

        context.drawImage(image, 0, 0);

        // Function to compress the image
        const compressImage = () => {
            const compressedDataURL = canvas.toDataURL("image/jpeg", quality);
            const compressedSize = compressedDataURL.length / 1024; // Size in KB
            console.log("Compressed Size:", compressedSize.toFixed(2) + " KB");
            // If the size is under 200KB, stop; otherwise, reduce quality and try again
            if (compressedSize > MAX_SIZE_KB && quality > 0.1) {
                quality -= 0.05; // Reduce quality in small steps
                compressImage(); // Recurse until size is acceptable
            } else if (compressedSize > MAX_SIZE_KB) {
                // If quality is too low, reduce canvas size
                canvas.width *= 0.9;
                canvas.height *= 0.9;
                context.drawImage(image, 0, 0, canvas.width, canvas.height);
                quality = 0.9; // Reset quality for the new size
                compressImage();
            } else {
                const originalSize = (file.size / 1024).toFixed(2); // in kilobytes
                console.log("Compressed Size:", compressedSize.toFixed(2) + " KB", "Original Size:", originalSize + " KB");

                // Set the selected image
                setPhoto(compressedDataURL);
            }
        };

        compressImage(); // Start the compression process
    };

    image.src = URL.createObjectURL(file);
    setOdometerPhoto(file2);
};

  const handleResetOdometer = () => {
    setPhoto("");
    setOdometerPhoto("");
    setPicture("");
    fileInputRef.current.value = "";
    setOdometerDetails({
      meterReading: "",
      remark: "",
      order_collected: null,
      new_ss_dealer_visited: null,
      new_ss_fabricator_demo: null,
      new_ss_retailer_visited: null
    });
    setIsCameraModalOpen(false);
  };

  const handleResetOdometerMobile = () => {
    setPhoto("");
    setOdometerPhoto("");
    setPicture("");
    fileInputRef.current.value = "";
    setOdometerDetails({
      meterReading: "",
      remark: "",
      // order_collected: null,
      // new_ss_dealer_visited: null,
      // new_ss_fabricator_demo: null,
      // new_ss_retailer_visited: null
    });
    setIsCameraModalOpenMobile(false);
  };

  const handleOpenCamera = () => {
    fileInputRef.current.click();
  };

  const resetFileInput = () => {
    fileInputRef.current.type = "text";
    fileInputRef.current.type = "file";
  };

  useEffect(() => {
    if (odometerPhoto) {
      setIsCameraModalOpenMobile(true);
    }
  }, [odometerPhoto]);

  function convertBase64ToFile(base64Data, filename) {
    // Extract base64 data (remove data URI prefix)
    const base64String = base64Data.replace(
      /^data:image\/(png|jpeg|jpg);base64,/,
      ""
    );

    // Convert base64 to binary data
    const binaryData = atob(base64String);

    // Create a Uint8Array to hold the binary data
    const length = binaryData.length;
    const uint8Array = new Uint8Array(length);
    for (let i = 0; i < length; i++) {
      uint8Array[i] = binaryData.charCodeAt(i);
    }

    // Create a Blob object from Uint8Array
    const blob = new Blob([uint8Array.buffer], { type: "image/jpeg" });

    // Create a File object from Blob
    const file = new File([blob], filename);

    // Create a new FormData object
    const formData = new FormData();
    formData.append("files", file);

    return formData;
  }

  // handleOdometer Submit 🚀🚀🚀
  const handleOdometerSubmit = async () => {
    // console.log(odometerPhoto, odometerDetails);
    console.log('punchh', !isPunchOut , !isPunchIn)
    let error = {}
    let showError = false;
    if(!isPunchOut && isPunchIn) {
      console.log('raman', odometerDetails)
      // if(!odometerDetails?.order_collected) {
      //   showError = true;
      //   error = {
      //     ...error,
      //     order_collected: true
      //   }
      // }
      for (let key in odometerDetails) {
        // if(!odometerDetails[key] && key !== "order_collected") {
        if(!odometerDetails[key]) {
          error = {...error, [key]: true}
          showError = true
        }
      }
    }
    if(!isPunchOut && !isPunchIn) {
      console.log('when punch in')
      for (let key in odometerDetails) {
        if(!odometerDetails[key] && key !== "order_collected" && key !== "new_ss_dealer_visited" && key !== "new_ss_fabricator_demo" && key !== "new_ss_retailer_visited") {
          error = {...error, [key]: true}
          showError = true
        }
      }
    }
    if(showError) {
      setErrorObj({ ...error })
      console.log('show error', error)
      return
    }
    if (!(picture || odometerPhoto)) {
      setMessage("Please add odometer picture!");
      setShowErrorModal(true);
      setShowLoading(false);
      return;
    }

    // if (!odometerDetails.meterReading) {
    //   setMessage("Please add odometer reading!");
    //   setShowErrorModal(true);
    //   setShowLoading(false);
    //   return;
    // }
    
    let data;
    data = convertBase64ToFile(picture, "image.JPG");
    // if (window.innerWidth <= 480) {
    //   // for mobile

    //   // let form = new FormData();
    //   // form.append(`files`, odometerPhoto);
    //   // data = form;

    //   // Convert the data URL to a Blob
    //   const blob = await fetch(photo).then((response) => response.blob());

    //   // Create a FormData object to send the image as part of the payload
    //   const formData = new FormData();
    //   formData.append("files", blob, "odometer.jpg");

    //   data = formData;
    // } else {
    //   // for Desktop
    //   data = convertBase64ToFile(picture, "image.JPG");
    // }
    
    console.log('odometer Details',odometerDetails)
    // return
    // console.log('after show error')
    try {
      setShowLoading(true);
      let response = await axios({
        method: "post",
        url:
          config.ipAddress +
          `/impressed?mobile=${phoneNum}&comment=${
            odometerDetails.remark
          }&uploaddate=${getCurrentDate()}&meterreading=${
            odometerDetails.meterReading
          }&order_collected=${odometerDetails.order_collected}&new_ss_dealer_visited=${odometerDetails.new_ss_dealer_visited}&new_ss_fabricator_demo=${odometerDetails.new_ss_fabricator_demo}&new_ss_retailer_visited=${odometerDetails.new_ss_retailer_visited}`,
        data: data,
        headers: {
          Accept: "application/json",
          "Content-Type": "multipart/form-data",
        },
      });

      console.log(response?.data?.code);
      setShowLoading(false);
      handleResetOdometer();
      if (response?.data?.code === 200) {
        if (isPunchIn) {
          handlePunchOut();
          setIsCameraModalOpen(false);
          setIsCameraModalOpenMobile(false);
          return;
        }
        handlePunchIn();
      }
      setIsCameraModalOpen(false);
      setIsCameraModalOpenMobile(false);
      setPicture("");
    } catch (error) {
      setMessage("Network error");
      setShowErrorModal(true);
      setIsCameraModalOpen(false);
      setIsCameraModalOpenMobile(false);
      setShowLoading(false);
      return;
    }
  };

  const openCameraModal = async () => {
    const { latitude, longitude } = await getCurrenttLocation();

    if (!(latitude && longitude)) {
      setMessage("Please enable your location!");
      setShowErrorModal(true);
      setShowLoading(false);
      return;
    }
    setIsCameraModalOpen(true);
    // handleOpenCamera();
    
    // if (window.innerWidth <= 480) {
    //   console.log("inside 480");
    //   // for mobile
    //   handleOpenCamera();
    // } else {
    //   // for Desktop
    //   setIsCameraModalOpen(true);
    // }
  };
  const capture = React.useCallback(() => {
    const pictureSrc = webcamRef.current.getScreenshot();
    setPicture(pictureSrc);
  });

  const handlePunchIn = async () => {
    const { latitude, longitude } = await getCurrenttLocation();

    setShowLoading(true);
    // localStorage.setItem("isPunchIn", true);
    // localStorage.setItem("punchInTime", punchinInTime);

    setIsPunchIN(true);
    // sending punchIn details to server 🚀🚀🚀
    let data = {
      mobile: phoneNum,
      punchtype: "attendance",
      latitude: latitude + "",
      longitude: longitude + "",
    };

    try {
      console.log(data);
      let response = await axios.post(
        config.ipAddress + `/attendance/punchin`,
        data
      );
      console.log(response.data.message);
      // alert(response.data.message);

      getUserAttendanceState();
      setShowAddButton(true);
      setShowLoading(false);
    } catch (error) {
      setMessage("Network Error!");
      setShowErrorModal(true);
      setShowLoading(false);
      return;
    }
  };

  const handlePunchInTime = () => {
    // let isPunchedIn = localStorage.getItem("isPunchIn");
    // let punchedInTime = localStorage.getItem("punchInTime");
    // if (!(isPunchedIn && punchedInTime)) return;
    // setIsPunchIN(Boolean(userAttendanceDetails[0]?.IsPunchIn));
    // setTimePunchIn(convertTime(userAttendanceDetails[0]?.timePunchIn));
  };

  const convertTime = (timeString) => {
    // const timeString = "13:02:45";
    if (!timeString) return;
    const [hours, minutes] = timeString.split(":").map(Number);

    let amPm = "AM";
    let formattedHours = hours % 12;
    if (formattedHours === 0) {
      formattedHours = 12;
    }
    if (hours >= 12) {
      amPm = "PM";
    }

    const formattedMinutes = minutes.toString().padStart(2, "0");

    const formattedTime = `${formattedHours}:${formattedMinutes} ${amPm}`;
    console.log(formattedTime);

    return formattedTime;
  };

  //   Punch Out functionality 🚀🚀

  const handlePunchOutPopup = () => {
    if (isPunchIn) {
      setPunchMessage("Are you sure ?");
      setIsModalOpen(true);
    }
  };

  // const handlePunchOutCancelandSuccess = (status) => {
  //   if (status) {
  //     handlePunchOut();
  //     setIsModalOpen(false);
  //   } else {
  //     setIsModalOpen(false);
  //   }
  // };

  const handlePunchOut = async () => {
    const { latitude, longitude } = await getCurrenttLocation();

    setShowAddButton(true);
    setIsPunchOut(true);

    // sending punchIn details to server 🚀🚀🚀
    let data = {
      mobile: phoneNum,
      punchtype: "attendance",
      latitude: latitude + "",
      longitude: longitude + "",
    };
    console.log(data);

    try {
      let response = await axios.post(
        config.ipAddress + `/attendance/punchout`,
        data
      );
      console.log(response.data.message);
      // alert(response.data.message);

      setShowAddButton(false);
      getUserAttendanceState();
    } catch (error) {
      setMessage("Network Error!");
      setShowErrorModal(true);
      setShowLoading(false);
    }
  };

  const handlePunchOutTime = () => {
    // let isPunchedOut = localStorage.getItem("isPunchOut");
    // let punchOutTime = localStorage.getItem("punchOutTime");
    // if (!(isPunchedOut && punchOutTime)) return;
    // setIsPunchOut(Boolean(userAttendanceDetails[0]?.IsPunchOut));
    // setTimePunchOut(convertTime(userAttendanceDetails[0]?.timePunchOut));
  };

  const removePunchInAndOutDetails = () => {
    // const currentDate = new Date();
    // const year = currentDate.getFullYear();
    // const month = String(currentDate.getMonth() + 1).padStart(2, "0");
    // const day = String(currentDate.getDate()).padStart(2, "0");
    // const formattedDate = `${year}-${month}-${day}`;

    let removeDate = localStorage.getItem("currentDate");
    let currentDate = new Date().getDate();

    intervalRef.current = setInterval(function () {
      console.log("Running...Interval");
      if (String(currentDate) !== String(removeDate)) {
        console.log("Running...Interval if");

        localStorage.removeItem("isPunchIn");
        localStorage.removeItem("punchInTime");
        localStorage.removeItem("isPunchOut");
        localStorage.removeItem("punchOutTime");

        window.location.reload();
      }
    }, 10000); // Check every 10 second
  };

  // get Current Date
  const getCurrentDate = () => {
    const today = new Date();
    const year = today.getFullYear();
    const month = today.getMonth() + 1;
    const day = today.getDate();

    const formattedDate = `${year}-${month}-${day}`;

    return formattedDate;
  };

  // get Dealer check in and Out list
  const getDealerCheckInandOutList = async () => {
    let phoneNum = localStorage.getItem("superonUserPhNum");
    let data = {
      mobile: phoneNum,
      visitdate: getCurrentDate(),
    };

    let response = await axios.post(config.ipAddress + `/visitbydate`, data);

    console.log(response.data.data);

    let allData = response?.data?.data;

    let filterData = allData.filter((item) => {
      return item.punchouttime !== null;
    });
    setDealerCheckInandOutList(filterData);
  };

  const convertTimeTo12HourFormat = (time) => {
    if (!time) return;
    let hours = parseInt(time.substring(0, 2));
    let minutes = time.substring(3, 5);
    let ampm = hours >= 12 ? "PM" : "AM";
    hours = hours % 12 || 12;
    return `${hours}:${minutes} ${ampm}`;
  };

  // console.log(convertTimeTo12HourFormat("11:25:24")); // Output: "11:25 AM"

  const convertIntoCamelCase = (name) => {
    let formattedName = name?.charAt(0) + name?.slice(1)?.toLowerCase();

    return formattedName;
  };

  // holding punchin and out time 🚀🚀🚀
  useEffect(() => {
    localStorage.setItem("userPunchInTime", timePunchIn);
    localStorage.setItem("userPunchOutTime", timePunchOut);
  }, [timePunchIn, timePunchOut]);

  const getUserAttendanceState = async () => {
    let phoneNum = localStorage.getItem("superonUserPhNum");
    let date = getCurrentDate();

    let response = await axios.get(
      config.ipAddress + `/attendance/state/${phoneNum}/${date}`
    );

    console.log(response?.data?.data);
    console.log(response?.data?.data?.attendance);

    let attendance = response?.data?.data?.attendance;
    let dailyActivities = response?.data?.data?.dailyactivity;

    setIsPunchIN(Boolean(attendance[0]?.IsPunchIn));
    setTimePunchIn(convertTime(attendance[0]?.timePunchIn));
    // console.log(Boolean(attendance[0]?.IsPunchIn));
    setShowAddButton(Boolean(attendance[0]?.IsPunchIn));

    setIsPunchOut(Boolean(attendance[0]?.IsPunchOut));
    setTimePunchOut(convertTime(attendance[0]?.timePunchOut));
    // console.log(attendance[0]?.IsPunchOut);
    if (Boolean(attendance[0]?.IsPunchOut)) {
      setShowAddButton(!Boolean(attendance[0]?.IsPunchOut));
    }

    setShowCheckOut(Boolean(dailyActivities[0]?.showcheckout));
    setDealerPunchInTime(convertTime(dailyActivities[0]?.punchIn));
    setDealerPunchInName(dailyActivities[0]?.dealername);
    setPunchInType(dailyActivities[0]?.types)
    setDealerUuid(dailyActivities[0]?.uuid);
    setDealerExtras({
      ...dealerExtras,
      dealermobile:dailyActivities[0]?.dealermobile,
      dealerlocation:dailyActivities[0]?.dealerlocation
    })
    console.log(dailyActivities[0]?.uuid);
  };

  useEffect(() => {
    getUserAttendanceState();
    getDealers();
    handleGetTypes()
    handleGetActions()
  }, []);

  useEffect(() => {
    // handlePunchInTime();
    // handlePunchOutTime();
    // removePunchInAndOutDetails();
    // return () => {
    //   clearInterval(intervalRef.current);
    // };
  }, [isPunchIn, isPunchOut]);

  const handleGetTypes = async()=>{
    try{
      let response = await axios.get(
        config.ipAddress + `/types`
      );
      console.log('handleGetTypes res',response?.data?.data)
      const tempTypesArr = response?.data?.data?.map(el=>{
        return{label:el.name,value:el.name}
      })
      setTypeOptions(tempTypesArr)
    }catch(error){
      console.log('handleGetTypes api error',error)
    }
  }
  const handleGetActions = async()=>{
    try{
      let response = await axios.get(
        config.ipAddress + `/nextactions`
      );
      console.log('handleGetActions res',response?.data?.data)
      const tempActionArr = response?.data?.data?.map(el=>{
        return{label:el.name,value:el.name}
      })
      setActionOptions(tempActionArr)
    }catch(error){
      console.log('handleGetActions api error',error)
    }
  }
  const days = ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"];
  const today = new Date();
  const getWeekDay = today.getDay();
  const currentDayName = days[getWeekDay]
  const sendMobile = async ()=> {
    try {
        const res = await axios.post(config.ipAddress + `/checkweeklyplanner`, {mobile: localStorage.getItem('superonUserPhNum')})
        console.log('checkweeklyplanner: ',res?.data?.data?.exist)
        const result = res?.data?.data?.exist;
        setWeeklyPlanStatus(result);
    } catch (error) {
        console.log('checkweeklyplanner error: ',error)
    }
  }
  useEffect(()=>{
      sendMobile();
  },[]);
  console.log('week week',weeklyPlanStatus)
  console.log('status',weeklyPlanStatus !== 0)

  const handleAddSampleRequest = (e) => {
    setAddsamplerequestStatus(false)
    const {name, value} = e.target
    setAddsamplerequest(value)
  }

  const handleDealerType = (e) => {
    const {name,value} = e.target
    setDealerInfo({
      ...dealerInfo,
      // existing: e.target.value,
      [name]:value
    })
  }
  console.log('dealer type', dealerInfo?.existing);

  const addField = ()=> {
    setSampleRequest([
      ...sampleRequest,
      {
        id: uuidv4(),
        item: '',
        dimension: '',
        totalquantity: '',
        quantityperpacket: ''
      }
    ])
  }
  console.log('sample', sampleRequest)
  const removeField = (id) => {
    setSampleRequest(sampleRequest.filter((request) => {
      return (request.id !== id)
    }))
  }
  const handleSampleRequest = (e,id) => {
    console.log('id',id, sampleRequest)
    const {name,value} = e.target
    const newSampleRequest = sampleRequest.map((item)=>{
      if(item.id === id) {
        return { ...item, [name]:value }
      }
      return item
    })
    console.log('value',e.target.value, newSampleRequest)
    setSampleRequest(newSampleRequest);
  }
  const validateForm = () => {
    let errors = {};
    let showErrorVal = false;
    if (!dealerInfo.existing) {
      errors.existing = 'Dealer type is required';
    }
    // if (!dealerInfo.name) {
    //   errors.name = 'Dealer name is required';
    // }
    if (!dealerInfo.address) {
      errors.address = 'Dealer address is required';
    }
    if (!dealerInfo.state) {
      errors.state = 'State is required';
    }
    if (!dealerInfo.city) {
      errors.city = 'City is required';
    }
    if (!dealerInfo.language) {
      errors.language = 'Language is required';
    }

    sampleRequest.forEach((item, index) => {
      if (!item.item) {
        showErrorVal = true;
        errors[`item_${index}`] = 'Item Description is required';
      }
      if (!item.dimension) {
        showErrorVal = true;
        errors[`dimension_${index}`] = 'Item Dimension is required';
      }
      if (!item.totalquantity) {
        showErrorVal = true;
        errors[`totalquantity_${index}`] = 'Total Quantity is required';
      }
      if (!item.quantityperpacket) {
        showErrorVal = true;
        errors[`quantityperpacket_${index}`] = 'Quantity Per Packet is required';
      }
    });

    setErrors(errors);
    return Object.keys(errors).length === 0; // Return true if no errors
  };

  console.log('sampleDealerName ', sampleDealerName)

  return (
    <div className=" bg-[#fafafa]  h-[89.2%] overflow-auto">
      <main className="  min-h-full px-3 py-3">
        {/* Section 1 */}

        <div className="rounded-[10px]  border-[1px] border-gray-300  shadow-6xl bg-white">
          <section className="flex justify-between items-center  border-b pb-4">
            <div className="flex gap-2 pt-4 ml-4 ">
              <div className="bg-[#FF971A] w-[2px] rounded-full"></div>
              <div className="">
                <h1 className="text-lg ">
                  {" "}
                  {isPunchOut ? "Attendance Marked" : "Mark Attendance"}
                </h1>
                <p>{date}</p>
              </div>
            </div>
            {showAddButton && (
              <div className="mr-4">
                <img
                  src={plusIcon}
                  alt="plus-icon"
                  className="w-5"
                  onClick={handleAddClick}
                />
              </div>
            )}
          </section>
          <section className="text-cente  py-4">
            {/* <span>{date}</span> <br />
          <h1 className="uppercase font-semibold">
            {isPunchOut ? "Attendance Marked" : "Mark Attendance"}
          </h1> */}
            <div className="flex justify-between items-center  px-4 bg-white ">
              {
              weeklyPlanStatus === 99 ?
              <>
                  <div
                      className="absolute z-20  text-center top-[35%] left-[50%] mt-[50px] mr-0 mb-0 ml-[-50px]"
                      // onBlur={() => setIsModalOpen(false)}
                  >
                      <img src={loadingGif} className="w-[100px]" alt="loading-logo" />
                  </div>
                  {/* <div
                  // onClick={() => setIsModalOpen(false)}
                  className="w-full h-[100dvh] bg-[#A8A8A8] absolute top-0 opacity-30 z-10"
                  ></div> */}
              </>
            :
              (weeklyPlanStatus !== 0)? 
                <>
                {/* Punch In */}
                  {!isPunchIn && (
                    <div className="flex  justify-center items-center gap-2  px-1 w-[100px] border-[1px] border-gray-200 rounded-md  h-[34px] shadow-4xl ">
                      <img src={clockIcon2} alt="clock=logo " />
                      <button
                        onClick={() => openCameraModal()}
                        // onClick={() => handlePunchIn()}
                        className=" text-[13px]"
                      >
                        Punch In
                      </button>
                    </div>
                  )}

                  {isPunchIn && (
                    <div className="flex gap-2 ml-3">
                      <img src={clockIcon2} alt="clock=logo" />

                      <div className="text-center text-sm ">
                        <span className="inline-block text-orange-light">
                          Punch In
                        </span>
                        <br />
                        <span className="inline-block text-[#535353] ">
                          {timePunchIn}
                        </span>
                      </div>
                    </div>
                  )}

                  {/* Punch Out */}

                  {!isPunchOut && (
                    <div className="flex  justify-center items-center gap-2  px-1 w-[100px] border-[1px] border-gray-200 rounded-md  h-[34px] shadow-4xl ">
                      <img src={clockIcon2} alt="clock=logo" />
                      <button
                        // onClick={() => handlePunchOut()}
                        onClick={() => {
                          if (isPunchIn) {
                            openCameraModal();
                          }
                        }}
                        className={` text-[13px] ${
                          isPunchIn ? "" : "text-[#AAAAAA]"
                        } `}
                      >
                        Punch Out
                      </button>
                    </div>
                  )}

                  {isPunchOut && (
                    <div className="flex gap-2 mr-3">
                      <img src={clockIcon2} alt="clock=logo" />

                      <div className="text-center text-sm ">
                        <span className="inline-block text-orange-light">
                          Punch Out
                        </span>
                        <br />
                        <span className="inline-block text-[#535353]">
                          {timePunchOut}
                        </span>
                      </div>
                    </div>
                  )}
                </>
                :
                <p>Please First Submit your <Link to="/dashboard/weeklyplaning" className="text-orange-light">Weekly Plan</Link></p>
              }
            </div>
          </section>
        </div>

        {/* camera open */}
        <input
          type="file"
          accept="image/*"
          capture="environment"
          ref={fileInputRef}
          onChange={handlePhotoChange}
          style={{ display: "none" }}
        />

        <section>
          {/* new code for checkIn and Out */}

          <div
            className={`${
              showCheckIn || showCheckOut ? "py-4 px-5 border-[1px]" : ""
            } w-full bg-white rounded-[10px] mt-4 shadow-6xl  border-gray-300 `}
          >
            {showCheckIn && (
              <div className=" bg-white ">
                <div className="flex gap-2 bg-white mb-3">
                  <div className="bg-[#FF971A] w-[2px] rounded-full"></div>
                  <div className="">
                    <h1 className="text-md ">
                      Visit {dealerCheckInandOutList.length + 1}
                    </h1>
                  </div>
                </div>

                <div className="w-full mb-4 relative">
                  <input
                    type="text"
                    placeholder="Dealer/Customer Name"
                    className="w-full h-[36px] border text-[14px] border-gray-300 py-[4px] px-2 rounded-[4px] placeholder:text-[12px] focus:outline-none focus:border-orange-light"
                    value={dealerName}
                    onChange={(e) => handleDealerChange(e)}
                    onFocus={() => setShowDealerList(true)}
                    onBlur={(e) => {
                      e.preventDefault();
                      setTimeout(() => {
                        setShowDealerList(false);
                      }, 0);

                      // clearTimeout(timer);
                    }}
                  />
                  <input
                    type="tel"
                    required
                    placeholder="Mobile"
                    className="my-2 w-full h-[36px] border text-[14px] border-gray-300 py-[4px] px-2 rounded-[4px] placeholder:text-[12px] focus:outline-none focus:border-orange-light"
                    value={dealerMobile}
                    onChange={(e) => setDealerMobile(e.target.value)}
                  />
                  <input
                    type="text"
                    required
                    placeholder="Location"
                    className="my-2 w-full h-[36px] border text-[14px] border-gray-300 py-[4px] px-2 rounded-[4px] placeholder:text-[12px] focus:outline-none focus:border-orange-light"
                    value={dealerLocation}
                    onChange={(e) => setDealerLocation(e.target.value)}
                  />
                  <Select
                  styles={selectCustomStyles}
        value={selectedType}
        onChange={e=>{
          console.log("option changed",e)
          setSelectedType([e])
          // handleSelectChangeType(e)
        }}
        options={typeOptions}
        className="mt-2"
        // placeholder="Select Type"
        placeholder={<div style={{fontSize:'12px'}}>Select category</div>} 
        
      />
                  {showDealerList && (
                    <div className="w-full absolute top-9 left-0 z-[1] bg-white border-[1px] border-gray-300 border-t-0 min-h-[30px] max-h-[200px] rounded-b-md overflow-y-auto">
                      {dealerList.length ? (
                        dealerList.map((list, index) => {
                          return (
                            <p
                              key={index}
                              onClick={() => handleDealerName(list.dealername)}
                              className="px-3 text-sm py-1 hover:bg-blue-300 "
                            >
                              {list.dealername}
                            </p>
                          );
                        })
                      ) : (
                        <h1 className="text-sm text-center py-1">
                          Dealer not found
                        </h1>
                      )}
                    </div>
                  )}
                </div>

                <div className="text-center">
                  <div className="flex justify-center items-center gap-2  px-1 w-[100px] border-[1px] border-gray-200 rounded-md  h-[34px] shadow-4xl mx-auto">
                    {/* <img src={clockIcon2} alt="clock=logo" /> */}
                    <button
                      // onClick={() => handlePunchOut()}
                      onClick={() => handleCheckInClick()}
                      className={`inline-block text-sm mx-auto`}
                    >
                      Check In
                    </button>
                  </div>
                </div>
                {/* <hr className="h-[1px] bg-gray-400" /> */}
              </div>
            )}

            {showCheckOut && (
              <div className="">
                {/* <label>Dealer Name:</label> */}

                <div className="flex gap-2 bg-white ">
                  <div className="bg-[#FF971A] w-[2px] rounded-full"></div>
                  <div className="">
                    <h1 className="text-md ">
                      Visit {dealerCheckInandOutList.length + 1}
                    </h1>
                  </div>
                </div>
                <div className=" pl-3 pr-1">
                  <h3 className="mt-1">
                    {/* {localStorage.getItem("superonDealerName")} */}
                    {convertIntoCamelCase(dealerPunchInName)}
                  </h3>
                  <h1 className="text-md">
                          Type :{" "}
                        <span className="text-black">
                          {convertIntoCamelCase(punchInType)}
                        </span>
                      </h1>
                      <h1 className="text-md">
                          Mobile :{" "}
                        <span className="text-black">
                          {convertIntoCamelCase(dealerExtras.dealermobile)}
                        </span>
                      </h1>  

                      <h1 className="text-md">
                          Location :{" "}
                        <span className="text-black">
                          {convertIntoCamelCase(dealerExtras.dealerlocation)}
                        </span>
                      </h1>  

                  <div className="flex items-center gap-2 mt-2">
                    <img
                      src={clockIcon2}
                      alt="clock-logo"
                      className="w-4 h-4"
                    />
                    <span className="text-sm">
                      {/* {dealerPunchInTime ||
                  
                      localStorage.getItem("dealerPunchInTime")} */}

                      {dealerPunchInTime}
                    </span>
                  </div>

                  {/* <textarea
                    type="text"
                    // id={`remarks-${index}`}
                    // name={`remarks-${index}`}
                    className=" w-full px-2 mt-3   border-[1px] text-sm border-gray-400  focus:outline-none focus:border-[#FF971A] rounded-[4px]"
                    // cols="40"
                    rows="2"
                    placeholder="Gift/ Sample Given"
                    value={gift}
                    onChange={(e) => setGift(e.target.value)}
                  /> */}
                  
                  <textarea
                    type="text"
                    // id={`remarks-${index}`}
                    // name={`remarks-${index}`}
                    className=" w-full px-2 mt-3   border-[1px] text-sm border-gray-400  focus:outline-none focus:border-[#FF971A] rounded-[4px]"
                    // cols="40"
                    rows="3"
                    // placeholder="SS Electrode / MIG / TIG Remarks"
                    placeholder="SS Remarks"
                    value={electrodeMigTigRemark}
                    onChange={(e) => setElectrodeMigTigRemark(e.target.value)}
                  />
                  <textarea
                    type="text"
                    // id={`remarks-${index}`}
                    // name={`remarks-${index}`}
                    className=" w-full px-2 mt-3   border-[1px] text-sm border-gray-400  focus:outline-none focus:border-[#FF971A] rounded-[4px]"
                    // cols="40"
                    rows="3"
                    placeholder="MCA/MSP Remarks"
                    value={mcaMspRemark}
                    onChange={(e) => setMcaMspRemark(e.target.value)}
                  />
                  <textarea
                    type="text"
                    // id={`remarks-${index}`}
                    // name={`remarks-${index}`}
                    className=" w-full px-2 mt-3   border-[1px] text-sm border-gray-400  focus:outline-none focus:border-[#FF971A] rounded-[4px]"
                    // cols="40"
                    rows="3"
                    // placeholder="Add Remarks"
                    placeholder="W Aux Remark"
                    value={remark}
                    onChange={(e) => setRemark(e.target.value)}
                  />
                  {/* <Select
                  styles={selectCustomStyles}
        value={selectedAction}
        onChange={e=>{
          setProductName('')
          setTargetPrice('')
          setVisitDate('')
          setProductQty('')
          setSelectedAction([e])
        }}
        options={actionOptions}
        className="mt-2"
        placeholder="Next Action"
      />
      {selectedAction?.length && selectedAction[0]?.label==="Repeat Visit Required" ? <div>
      <Datepicker
                  // portalId="root-portal"
                  popperClassName="some-custom-class1"
                  selected={visitDate}
                  onChange={(date) =>
                    setVisitDate(date)
                  }
                  popperPlacement="bottom"
                  popperModifiers={{
                    flip: { behavior: ["bottom"] },
                    preventOverflow: { enabled: true },
                    hide: { enabled: false },
                  }}
                  dateFormat={"yyyy/MM/dd"}
                  placeholderText="Visit date"
                  customInput={
                    <input
                      value={visitDate}
                      className="example-custom-input"
                      onChange={(e) =>
                        setVisitDate(e.target.value)
                      }
                    ></input>
                  }
                  wrapperClassName="datePicker mt-2"
                ></Datepicker>
      </div> : null

      }
      {selectedAction?.length && selectedAction[0]?.label==="Price Request From HO" ? <div>
      <input
                    type="text"
                    placeholder="Product Name"
                    className="my-2 w-full h-[36px] border text-[14px] border-gray-300 py-[4px] px-2 rounded-[4px] placeholder:text-[12px] focus:outline-none focus:border-orange-light"
                    value={productName}
                    onChange={(e) => setProductName(e.target.value)}
                  />
                  <input
                    type="number"
                    placeholder="Target Price"
                    className="mb-2 w-full h-[36px] border text-[14px] border-gray-300 py-[4px] px-2 rounded-[4px] placeholder:text-[12px] focus:outline-none focus:border-orange-light"
                    value={targetPrice}
                    onChange={(e) => setTargetPrice(e.target.value)}
                  />
      </div> : null

}
{selectedAction?.length && selectedAction[0]?.label==="Sample Request" ? <div>
      <input
                    type="text"
                    placeholder="Product Name"
                    className="my-2 w-full h-[36px] border text-[14px] border-gray-300 py-[4px] px-2 rounded-[4px] placeholder:text-[12px] focus:outline-none focus:border-orange-light"
                    value={productName}
                    onChange={(e) => setProductName(e.target.value)}
                  />
                  <input
                    type="number"
                    placeholder="Quantity"
                    className="mb-2 w-full h-[36px] border text-[14px] border-gray-300 py-[4px] px-2 rounded-[4px] placeholder:text-[12px] focus:outline-none focus:border-orange-light"
                    value={productQty}
                    onChange={(e) => setProductQty(e.target.value)}
                  />
      </div> : null

      } */}
                  <div className="field flex flex-wrap items-center mt-5 mb-5">
                    <label
                      style={{ marginRight: '16px' }}
                    >Add Sample Request : </label>
                    <label
                      htmlFor="yes"
                      style={{ marginRight: '16px', fontSize: '14px', display: 'flex', flexWrap: 'wrap', alignItems: 'center' }}
                    >
                      <input
                        type="radio"
                        class={`w-auto h-[auto]`}
                        style={{ marginRight: '8px' }}
                        value="Yes"
                        name="addsample"
                        id="yes"
                        checked={addsamplerequest === "Yes"}
                        onChange={(e) => {
                          handleAddSampleRequest(e)
                          // setErrors({ ...errors, existing: '' })
                        }}
                      />
                      Yes
                    </label>
                    <label
                      htmlFor="no"
                      style={{ marginRight: '16px', fontSize: '14px', display: 'flex', flexWrap: 'wrap', alignItems: 'center' }}
                    >
                      <input
                        type="radio"
                        class={`w-auto h-[auto] mr-[2]`}
                        style={{ marginRight: '8px' }}
                        value="No"
                        name="addsample"
                        id="no"
                        checked={addsamplerequest === "No"}
                        onChange={(e) => {
                          handleAddSampleRequest(e)
                          // setErrors({ ...errors, existing: '' })
                        }}
                      />
                      No
                    </label>
                    {addsamplerequestStatus && <p className="error-field w-[100%] text-[14px]">Please Select Sample Request Yes or No</p>}
                  </div>
                </div>
                
                {addsamplerequest === "Yes" &&
                  <div className="pl-3 pr-1 mt-5">
                    {/* Sample Request Start */}
                    {/* <div className="flex gap-2 mb-1 ">
                      <div className="bg-[#FF971A] w-[2px] "></div>
                      <label htmlFor="" className="text-lg ">Add Sample Request</label>
                    </div> */}
                    <div className="dealer-info">
                      <div className="field flex flex-wrap items-center mt-5 mb-5">
                        <label
                          style={{ marginRight: '16px' }}
                        >Dealer Type : </label>
                        <label
                          htmlFor="new"
                          style={{ marginRight: '16px', fontSize: '14px', display: 'flex', flexWrap: 'wrap', alignItems: 'center' }}
                        >
                          <input
                            type="radio"
                            class={`w-auto h-[auto]`}
                            style={{ marginRight: '8px' }}
                            value="New"
                            name="existing"
                            id="new"
                            checked={dealerInfo?.existing === "New"}
                            onChange={(e) => {
                              handleDealerType(e)
                              setErrors({ ...errors, existing: '' })
                            }}
                          />
                          New
                        </label>
                        <label
                          htmlFor="existing"
                          style={{ marginRight: '16px', fontSize: '14px', display: 'flex', flexWrap: 'wrap', alignItems: 'center' }}
                        >
                          <input
                            type="radio"
                            class={`w-auto h-[auto] mr-[2]`}
                            style={{ marginRight: '8px' }}
                            value="Existing"
                            name="existing"
                            id="existing"
                            checked={dealerInfo?.existing === "Existing"}
                            onChange={(e) => {
                              handleDealerType(e)
                              setErrors({ ...errors, existing: '' })
                            }}
                          />
                          Existing
                        </label>
                        {errors.existing && <p className="error-field w-[100%] text-[14px]">{errors.existing}</p>}
                      </div>
                      <div className="field">
                        <input
                          type="text"
                          placeholder="Name"
                          class={`w-full h-[36px] border text-[14px] border-gray-300 py-[4px] px-1 rounded-[4px] placeholder:text-[12px] focus:outline-none ${errors?.name ? 'error-field' : ''}`}
                          value={sampleDealerName}
                          name="name"
                          // onChange={(e) => {
                          //   handleDealerType(e)
                          //   setErrors({ ...errors, name: '' })
                          // }}
                          disabled
                        />
                      </div>
                      <div className="field">
                        <input
                          type="text"
                          placeholder="Address"
                          class={`w-full h-[36px] border text-[14px] border-gray-300 py-[4px] px-1 rounded-[4px] placeholder:text-[12px] focus:outline-none  ${errors?.address ? 'error-field' : ''}`}
                          value={dealerInfo?.address}
                          name="address"
                          onChange={(e) => {
                            handleDealerType(e)
                            setErrors({ ...errors, address: '' })
                          }}
                        />
                      </div>
                      <div className="field">
                        <input
                          type="text"
                          placeholder="State"
                          class={`w-full h-[36px] border text-[14px] border-gray-300 py-[4px] px-1 rounded-[4px] placeholder:text-[12px] focus:outline-none ${errors?.state ? 'error-field' : ''}`}
                          value={dealerInfo?.state}
                          name="state"
                          onChange={(e) => {
                            handleDealerType(e)
                            setErrors({ ...errors, state: '' })
                          }}
                        />
                      </div>
                      <div className="field">
                        <input
                          type="text"
                          placeholder="City"
                          class={`w-full h-[36px] border text-[14px] border-gray-300 py-[4px] px-1 rounded-[4px] placeholder:text-[12px] focus:outline-none ${errors?.city ? 'error-field' : ''}`}
                          value={dealerInfo?.city}
                          name="city"
                          onChange={(e) => {
                            handleDealerType(e)
                            setErrors({ ...errors, city: '' })
                          }}
                        />
                      </div>
                      <div className="field">
                        <input
                          type="text"
                          placeholder="Language"
                          class={`w-full h-[36px] border text-[14px] border-gray-300 py-[4px] px-1 rounded-[4px] placeholder:text-[12px] focus:outline-none ${errors?.language ? 'error-field' : ''}`}
                          value={dealerInfo?.language}
                          name="language"
                          onChange={(e) => {
                            handleDealerType(e)
                            setErrors({ ...errors, language: '' })
                          }}
                        />
                      </div>
                    </div>
                    <div className="sample-request">
                      {sampleRequest.map((item, index) => (
                        <div key={item?.id} className="form-group relative">
                          {sampleRequest.length > 1 && (
                            <button
                              onClick={() => removeField(item.id)}
                              className="cursor-pointer"
                              style={{ position: 'absolute', right: '10px', top: '10px', }}
                            ><img src={crossIcon} alt="Delete" width="16" /></button>
                          )}
                          <div className="field">
                            <label>Item Description</label>
                            <input
                              type="text"
                              placeholder="Mention Complete Product Description"
                              class={`w-full h-[36px] border text-[14px] border-gray-300 py-[4px] px-1 rounded-[4px] placeholder:text-[12px] focus:outline-none ${errors[`item_${index}`] ? 'error-field' : ''}`}
                              value={item?.item}
                              name="item"
                              onChange={(e) => {
                                handleSampleRequest(e, item.id)
                                setErrors({ ...errors, [`item_${index}`]: '' })
                              }}
                            />
                          </div>
                          <div className="field">
                            <label>Item Dimension</label>
                            <input
                              type="text"
                              placeholder="Mention Complete Product Dimension"
                              class={`w-full h-[36px] border text-[14px] border-gray-300 py-[4px] px-1 rounded-[4px] placeholder:text-[12px] focus:outline-none ${errors[`dimension_${index}`] ? 'error-field' : ''}`}
                              value={item?.dimension}
                              name="dimension"
                              onChange={(e) => {
                                handleSampleRequest(e, item.id)
                                setErrors({ ...errors, [`dimension_${index}`]: '' })
                              }}
                            />
                          </div>
                          <div className="field">
                            <label>Total Quantity Required</label>
                            <input
                              type="text"
                              placeholder="Mention No. of Pcs/Kg"
                              class={`w-full h-[36px] border text-[14px] border-gray-300 py-[4px] px-1 rounded-[4px] placeholder:text-[12px] focus:outline-none ${errors[`totalquantity_${index}`] ? 'error-field' : ''}`}
                              value={item?.totalquantity}
                              name="totalquantity"
                              onChange={(e) => {
                                handleSampleRequest(e, item.id)
                                setErrors({ ...errors, [`totalquantity_${index}`]: '' })
                              }}
                            />
                          </div>
                          <div className="field">
                            <label>Quantity Per Packet</label>
                            <input
                              type="text"
                              placeholder="Type here"
                              class={`w-full h-[36px] border text-[14px] border-gray-300 py-[4px] px-1 rounded-[4px] placeholder:text-[12px] focus:outline-none ${errors[`quantityperpacket_${index}`] ? 'error-field' : ''}`}
                              
                              value={item?.quantityperpacket}
                              name="quantityperpacket"
                              onChange={(e) => {
                                handleSampleRequest(e, item.id)
                                setErrors({ ...errors, [`quantityperpacket_${index}`]: '' })
                              }}
                            />
                          </div>
                        </div>
                      ))}
                    </div>
                    {/* Sample Request End */}
                  </div>
                }

                <div className="flex justify-end items-center gap-2 mt-2 mr-1">
                  {addsamplerequest === "Yes" &&
                    <button
                      type="button"
                      onClick={addField}
                      className="px-0 py-0 mt-2"
                      style={{marginRight: 'auto'}}
                    >
                      {/* Add more */}
                      <img src={addMore} width={20} alt="" />
                    </button>
                  }
                  <div className=" border-[1px] border-gray-200 w-[35px] h-[25px]  rounded-[4px] flex items-center justify-center">
                    <img
                      src={cancelLogo}
                      alt="submit-logo"
                      className="w-4 "
                      onClick={() => handleCancelClick()}
                    />
                  </div>
                  <div className="border-[1px] flex justify-center items-center border-gray-200 w-[35px] h-[25px]  rounded-[4px]">
                    <img
                      src={submitLogo}
                      alt="submit-logo"
                      className="w-[18px]  "
                      onClick={() => handleCheckOutClick()}
                    />
                  </div>
                </div>
              </div>
            )}
          </div>
        </section>

        <section className="bg-[#fff] mt-4  ">
          {dealerCheckInandOutList.length
            ? dealerCheckInandOutList.map((item, index) => {
                return (
                  <div
                    key={index}
                    className=" bg-white mt-4 rounded-[10px] shadow-6xl pl-4 border-[1px] border-gray-300"
                  >
                    <div className="flex flex-col gap-1 py-3   ">
                      <div className="flex gap-2 bg-white ">
                        <div className="bg-[#FF971A] w-[2px] rounded-full"></div>
                        <div className="">
                          <h1 className="text-md ">Visit {index + 1}</h1>
                        </div>
                      </div>
                      <h1 className="text-md  text-orange-light">
                        <span className="text-black ml-3">
                          {convertIntoCamelCase(item.dealername)}
                        </span>
                      </h1>
                      {
                        item?.types ? <h1 className="text-md   ml-3">
                          Type :{" "}
                        <span className="text-black">
                          {convertIntoCamelCase(item?.types)}
                        </span>
                      </h1> : null
                      }

                      {
                        item?.extra1 ? <h1 className="text-md   ml-3">
                          Mobile :{" "}
                        <span className="text-black">
                          {convertIntoCamelCase(item?.extra1)}
                        </span>
                      </h1> : null
                      }

                      {
                        item?.extra2 ? <h1 className="text-md   ml-3">
                          Location :{" "}
                        <span className="text-black">
                          {convertIntoCamelCase(item?.extra2)}
                        </span>
                      </h1> : null
                      }
                      
                      <div className="  ml-3 gap-2">
                        <img
                          src={clockIcon2}
                          alt="clock-logo"
                          className="w-4 inline-block"
                        />
                        <p className=" text-sm text-black inline-block ml-2">
                          {convertTimeTo12HourFormat(item.punchintime)} -{" "}
                          {convertTimeTo12HourFormat(item.punchouttime)}
                        </p>
                      </div>
                      {/* <p className=" text-base text-black  ml-3">
                        Gift/Sample Given :{" "}
                        <span className="font-medium   ">{item.extra3}</span>
                      </p> */}
                      <p className=" text-base text-black  ml-3">
                        SS Remarks :{" "}
                        <span className="font-medium   ">{item.extra5}</span>
                      </p>
                      <p className=" text-base text-black  ml-3">
                        MCA/MSP Remarks :{" "}
                        <span className="font-medium   ">{item.extra4}</span>
                      </p>
                      <p className=" text-base text-black  ml-3">
                        W Aux Remarks :{" "}
                        <span className="font-medium   ">{item.remark}</span>
                      </p>
                      {item?.nextactions ? <p className=" text-base text-black  ml-3">
                        Next Action :{" "}
                        <span className="font-medium   ">{item.nextactions}</span>
                      </p> : null}
                      {item?.repeatvisitdate ? <p className=" text-base text-black  ml-3">
                        Visit Date :{" "}
                        <span className="font-medium   ">{item?.repeatvisitdate}</span>
                      </p> : null }
                      {item?.productname ? <p className=" text-base text-black  ml-3">
                        Product Name :{" "}
                        <span className="font-medium   ">{item?.productname}</span>
                      </p> : null }
                      {item?.targetprice ? <p className=" text-base text-black  ml-3">
                        Target Price :{" "}
                        <span className="font-medium   ">{item?.targetprice}</span>
                      </p> : null }
                      {item?.quantity ? <p className=" text-base text-black  ml-3">
                      Quantity :{" "}
                        <span className="font-medium   ">{item?.quantity}</span>
                      </p> : null }
                    </div>

                    {/* <hr className="h-[0px] bg-gray-400" /> */}
                  </div>
                );
              })
            : // <h1 className="text-lg font-bold ml-8 py-3">No Data to Display!</h1>
              ""}
        </section>
      </main>

      {isModalOpen ? (
        <div
          className="absolute z-20  text-center top-[35%] left-[50%] mt-[50px] mr-0 mb-0 ml-[-150px]"
          // onBlur={() => setIsModalOpen(false)}
        >
          <PunchPopup
            setIsModalOpen={setIsModalOpen}
            message={punchMessage}
            handlePunchCancelandSuccess={handlePunchCancelandSuccess}
            // handlePunchOutCancelandSuccess={handlePunchOutCancelandSuccess}
          />

          {/* <button
            className="px-6 py-2 mt-3 bg-red-600 font-semibold text-white rounded-lg "
            onClick={() => setIsModalOpen(false)}
          >
            Close
          </button> */}
        </div>
      ) : (
        ""
      )}
      {isModalOpen ? (
        <div
          onClick={() => setIsModalOpen(false)}
          className="w-full h-[100dvh] bg-[#A8A8A8] absolute top-0 opacity-30 z-10"
        ></div>
      ) : (
        ""
      )}

      {showLoading && (
        <div
          className="absolute z-40  text-center top-[35%] left-[50%] mt-[50px] mr-0 mb-0 ml-[-50px]"
          // onBlur={() => setIsModalOpen(false)}
        >
          <img src={loadingGif} className="w-[70px]" alt="loading-logo" />
        </div>
      )}

      {showLoading && (
        <div
          // onClick={() => setIsModalOpen(false)}
          className="w-full h-[100dvh] bg-black absolute top-0 opacity-75 z-30"
        ></div>
      )}

      {isModalOpen2 ? (
        <div
          className="absolute z-20  text-center top-[35%] left-[50%] mt-[50px] mr-0 mb-0 ml-[-150px]"
          // onBlur={() => setIsModalOpen(false)}
        >
          <Popup setIsModalOpen={setIsModalOpen2} />

          {/* <button
            className="px-6 py-2 mt-3 bg-red-600 font-semibold text-white rounded-lg "
            onClick={() => setIsModalOpen(false)}
          >
            Close
          </button> */}
        </div>
      ) : (
        ""
      )}
      {isModalOpen2 ? (
        <div
          onClick={() => setIsModalOpen2(false)}
          className="w-full h-[100dvh] bg-[#A8A8A8] absolute top-0 opacity-30 z-10"
        ></div>
      ) : (
        ""
      )}

      {showErrorModal ? (
        <div
          className="absolute z-40  text-center top-[35%] left-[50%] mt-[50px] mr-0 mb-0 ml-[-150px]"
          // onBlur={() => setIsModalOpen(false)}
        >
          <ErrorPopup setShowErrorModal={setShowErrorModal} message={message} />

          {/* <button
            className="px-6 py-2 mt-3 bg-red-600 font-semibold text-white rounded-lg "
            onClick={() => setIsModalOpen(false)}
          >
            Close
          </button> */}
        </div>
      ) : (
        ""
      )}
      {showErrorModal ? (
        <div
          onClick={() => setShowErrorModal(false)}
          className="w-full h-[100dvh] bg-[#A8A8A8] absolute top-0 opacity-30 z-30"
        ></div>
      ) : (
        ""
      )}

      {/*Odometer Image capture Mobile 🚀🚀🚀 */}
      {isCameraModalOpenMobile && (
        <section className="bg-white px-4 py-4 absolute z-20 h-[80%] w-[96dvw] text-center  left-[50%] top-[12%] mr-0 mb-0 ml-[-48vw] border-[1px] rounded-[10px] border-gray-300 shadow-md overflow-auto">
          <div className="relative">
            <div className="flex gap-2 mb-2">
              <div className="bg-[#FF971A] w-[2px] "></div>
              <div className="">
                <h1 className="text-lg ">Add Odometer Picture</h1>
              </div>
            </div>

            {!photo && (
              // <button onClick={handleOpenCamera}>Capture Odometer</button>
              <label
                onClick={handleOpenCamera}
                htmlFor="dropzone-file"
                className="flex flex-col items-center justify-center w-full py-3 mt-3 border-2 border-gray-300 border-dashed rounded-lg cursor-pointer bg-gray-50  hover:bg-gray-100"
              >
                <div class="flex flex-col items-center justify-center py-5">
                  <svg
                    aria-hidden="true"
                    className="w-8 mb-3 text-gray-400"
                    fill="none"
                    stroke="currentColor"
                    viewBox="0 0 24 24"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      stroke-width="2"
                      d="M7 16a4 4 0 01-.88-7.903A5 5 0 1115.9 6L16 6a5 5 0 011 9.9M15 13l-3-3m0 0l-3 3m3-3v12"
                    ></path>
                  </svg>
                  <p class="mb-2 text-sm text-gray-500 ">
                    <span class="font-semibold">Click here to capture</span>
                  </p>
                </div>
              </label>
            )}

            {photo && (
              <div className="mt-3">
                <div className="p-2 border-[1px] border-gray-300 rounded-md">
                  <img
                    src={photo}
                    alt="odometer"
                    className="w-full h-[280px]"
                  />
                </div>
              </div>
            )}

            <div className="mt-2">
              <input
                type="number"
                placeholder="Meter Reading"
                className="w-full h-[36px] border text-[14px] border-gray-300 py-[4px] px-1 rounded-[4px]  focus:outline-none focus:border-orange-light"
                value={odometerDetails.meterReading}
                onChange={(e) =>
                  setOdometerDetails({
                    ...odometerDetails,
                    meterReading: e.target.value,
                  })
                }
              />
              <textarea
                type="text"
                className="w-full p-1  mt-3 border-[1px] text-sm focus:outline-none focus:border-orange-light border-gray-300 rounded-[4px] "
                cols="30"
                rows="2"
                placeholder="Remark"
                value={odometerDetails.remark}
                onChange={(e) =>
                  setOdometerDetails({
                    ...odometerDetails,
                    remark: e.target.value,
                  })
                }
              />
            </div>

            <div className="">
              <div className="flex gap-4 justify-end mt-1">
                <div className="flex justify-end items-center gap-2">
                  <div className=" border-[1px] border-gray-200 w-[35px] h-[25px]  rounded-[4px] flex items-center justify-center">
                    <img
                      src={crossIcon}
                      alt="submit-logo"
                      className="w-4 "
                      onClick={() => {
                        handleResetOdometerMobile();
                      }}
                    />
                  </div>
                  <div className=" border-[1px] border-gray-200 w-[35px] h-[25px]  rounded-[4px] flex items-center justify-center">
                    <img
                      src={resetLogo}
                      alt="submit-logo"
                      className="w-4 "
                      onClick={() => {
                        // handleRetake();
                        handleOpenCamera();
                      }}
                    />
                  </div>
                  <div className="border-[1px] flex justify-center items-center border-gray-200 w-[35px] h-[25px]  rounded-[4px]">
                    <img
                      src={submitLogo}
                      alt="submit-logo"
                      className="w-[18px]  "
                      onClick={() => handleOdometerSubmit()}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      )}
      {isCameraModalOpenMobile ? (
        <div
          onClick={() => setIsCameraModalOpen(false)}
          className="w-full h-[100dvh] bg-[#A8A8A8] absolute top-0 opacity-30 z-10"
        ></div>
      ) : (
        ""
      )}

      {/*Odometer Image capture  desktop🚀🚀🚀 */}
      {isCameraModalOpen && (
        <section className="bg-white px-4 py-4 absolute z-20 h-[80%] w-[96dvw] text-center  left-[50%] top-[12%] mr-0 mb-0 ml-[-48vw] border-[1px] rounded-[10px] border-gray-300 shadow-md overflow-auto">
          <div className="relative">
            {/* webcam  */}
            <div className="attendance-modal">
              {!isPunchOut && isPunchIn &&
                <>
                <div className="flex gap-2 mb-2">
                  <div className="bg-[#FF971A] w-[2px] "></div>
                  <div className="">
                    <h1 className="text-lg ">SS Output of the day</h1>
                  </div>
                </div>
                <div className="field">
                  <label>Order Collected</label>
                  <input
                    type="number"
                    placeholder="No. of Order Collected"
                    className={`w-full h-[36px] border text-[14px] border-gray-300 py-[4px] px-1 rounded-[4px]  focus:outline-none focus:border-orange-light mt-3 ${errorObj?.order_collected ? "error-field" : ""}`}
                    value={odometerDetails.order_collected}
                    onChange={(e) => {
                      setErrorObj({...errorObj, order_collected: false})
                      setOdometerDetails({
                        ...odometerDetails,
                        order_collected: e.target.value,
                      })
                    }}
                  />
                </div>
                <div className="field">
                  <label>New SS Dealer Visited</label>
                  <input
                    type="number"
                    placeholder="No. of New SS Dealer Visited"
                    className={`w-full h-[36px] border text-[14px] border-gray-300 py-[4px] px-1 rounded-[4px]  focus:outline-none focus:border-orange-light mt-3 ${errorObj?.new_ss_dealer_visited ? "error-field" : ""}`}
                    value={odometerDetails.new_ss_dealer_visited}
                    onChange={(e) => {
                      setErrorObj({...errorObj, new_ss_dealer_visited: false})
                      setOdometerDetails({
                        ...odometerDetails,
                        new_ss_dealer_visited: e.target.value,
                      })
                    }}
                  />
                </div>
                <div className="field">
                  <label>New SS Fabricator Demo</label>
                  <input
                    type="number"
                    placeholder="No. of New SS Fabricator Demo"
                    className={`w-full h-[36px] border text-[14px] border-gray-300 py-[4px] px-1 rounded-[4px]  focus:outline-none focus:border-orange-light mt-3 ${errorObj?.new_ss_fabricator_demo ? "error-field" : ""}`}
                    value={odometerDetails.new_ss_fabricator_demo}
                    onChange={(e) => {
                      setErrorObj({...errorObj, new_ss_fabricator_demo: false})
                      setOdometerDetails({
                        ...odometerDetails,
                        new_ss_fabricator_demo: e.target.value,
                      })
                    }}
                  />
                </div>
                <div className="field">
                  <label>New SS Retailer Visited</label>
                  <input
                    type="number"
                    placeholder="No. of New SS Retailer Visited"
                    className={`w-full h-[36px] border text-[14px] border-gray-300 py-[4px] px-1 rounded-[4px]  focus:outline-none focus:border-orange-light mt-3 ${errorObj?.new_ss_retailer_visited ? "error-field" : ""}`}
                    value={odometerDetails.new_ss_retailer_visited}
                    onChange={(e) => {
                      setErrorObj({...errorObj, new_ss_retailer_visited: false})
                      setOdometerDetails({
                        ...odometerDetails,
                        new_ss_retailer_visited: e.target.value,
                      })
                    }}
                  />
                </div>
                </>
              }
              <div className="flex gap-2 mb-2 mt-8">
                <div className="bg-[#FF971A] w-[2px] "></div>
                <div className="">
                  <h1 className="text-lg ">Add Odometer Picture</h1>
                </div>
              </div>
              <div>
                {picture == "" ? (
                  <div className="p-2 border-[1px]  w-full  border-gray-300 rounded-md mt-3">
                    <Webcam
                      audio={false}
                      ref={webcamRef}
                      width={100 + "%"}
                      height={100 + "%"}
                      // height={window.innerHeight}
                      screenshotFormat="image/jpeg"
                      videoConstraints={{
                        // width: 300,
                        screenshotQuality: 10,
                        height: 200,
                        facingMode,
                      }}
                      capture
                      className="w-full"
                    />
                  </div>
                ) : (
                  <div className="p-2 border-[1px] border-gray-300 rounded-md">
                    <img
                      src={picture}
                      alt="odometer"
                      className="w-full h-[250px]"
                    />
                  </div>
                )}
              </div>
              <div className="flex justify-end"></div>
            </div>

            <div className="">
              <input
                type="number"
                placeholder="Meter Reading"
                className={`w-full h-[36px] border text-[14px] border-gray-300 py-[4px] px-1 rounded-[4px]  focus:outline-none focus:border-orange-light mt-3 ${errorObj?.meterReading ? "error-field" : ""}`}
                value={odometerDetails.meterReading}
                onChange={(e) => {
                  setErrorObj({ ...errorObj, meterReading: false })
                  setOdometerDetails({
                    ...odometerDetails,
                    meterReading: e.target.value,
                  })
                }}
              />
              <textarea
                type="text"
                className={`w-full p-1  mt-3 border-[1px] text-sm focus:outline-none focus:border-orange-light border-gray-300 rounded-[4px] ${errorObj?.remark ? "error-field" : ""}`}
                cols="30"
                rows="2"
                placeholder="Remark"
                value={odometerDetails.remark}
                onChange={(e) => {
                  setErrorObj({ ...errorObj, remark: false })
                  setOdometerDetails({
                    ...odometerDetails,
                    remark: e.target.value,
                  })
                }}
              />
            </div>

            <div className="">
              <div className="flex gap-4 justify-end mt-1">
                <div className="flex justify-end items-center gap-2">
                  {picture != "" ? (
                    <div className=" border-[1px] border-gray-200 w-[35px] h-[25px]  rounded-[4px] flex items-center justify-center">
                      <img
                        src={resetLogo}
                        alt="submit-logo"
                        className="w-4"
                        onClick={(e) => {
                          e.preventDefault();
                          setPicture("");
                        }}
                      />
                    </div>
                  ) : (
                    <div className=" flex gap-2">
                      <div className="border-[1px] flex justify-center items-center border-gray-200 w-[35px] h-[25px]  rounded-[4px]">
                        <img
                          src={cameraSwitch}
                          alt="submit-logo"
                          className="w-[18px]  "
                          onClick={(e) => {
                            e.preventDefault();
                            if (facingMode === "environment") {
                              setFacingMode("user");
                            }
                            if (facingMode === "user") {
                              setFacingMode("environment");
                            }
                          }}
                        />
                      </div>
                      <div className="border-[1px] flex justify-center items-center border-gray-200 w-[35px] h-[25px]  rounded-[4px]">
                        <img
                          src={cameraCapture}
                          alt="submit-logo"
                          className="w-[18px]  "
                          onClick={(e) => {
                            e.preventDefault();
                            capture();
                          }}
                        />
                      </div>
                    </div>
                  )}

                  <div className=" border-[1px] border-gray-200 w-[35px] h-[25px]  rounded-[4px] flex items-center justify-center">
                    <img
                      src={crossIcon}
                      alt="submit-logo"
                      className="w-4 "
                      onClick={() => {
                        handleResetOdometer();
                      }}
                    />
                  </div>

                  <div className="border-[1px] flex justify-center items-center border-gray-200 w-[35px] h-[25px]  rounded-[4px]">
                    <img
                      src={submitLogo}
                      alt="submit-logo"
                      className="w-[18px]  "
                      onClick={() => handleOdometerSubmit()}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      )}
      {isCameraModalOpen ? (
        <div
          onClick={() => setIsCameraModalOpen(false)}
          className="w-full h-[100dvh] bg-[#A8A8A8] absolute top-0 opacity-30 z-10"
        ></div>
      ) : (
        ""
      )}
    </div>
  );
}

export default Attendance;
